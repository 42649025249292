<template>
  <div class="user-info">
    <div class="user-info-self">
      <div class="user-info-base">
        <div class="user-info-content">
          <template v-if="isSelf">
            <div class="user-info-c-head">
              <el-avatar
                style="width: 160px;height:160px;"
                :src="userBaseInfo.avatar"
              ></el-avatar>
            </div>
            <p class="name">{{userBaseInfo.realname}}<i
                class="el-icon-edit"
                @click="showDialog = true"
              ></i></p>
          </template>
          <template v-else>
            <div class="user-info-c-head">
              <el-avatar
                style="width: 160px;height:160px;"
                :src="userInfo.avatar"
              ></el-avatar>
            </div>
            <p class="name">{{userInfo.realname}}</p>
          </template>
          <div class="desc">
            <ul>
              <li><span>累计课程单元数</label></span><label>{{studyDataNumber.courseUnitNumber}}</label></li>
              <li><span>应上课次数</span><label>{{studyDataNumber.totalClassNumber}}</label></li>
              <li><span>正常考勤次数</span><label>{{studyDataNumber.normalClassNumber}}</label></li>
              <li><span>缺勤次数</span><label>{{studyDataNumber.absentClassNumber}}</label></li>
              <li><span>提交课程作品次数</label></span><label>{{studyDataNumber.absentWorkNumber}}</label></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="user-info-body">
      <div class="user-info-program-item">
        <div class="search">
          <div class="el-select">
            <el-date-picker
              clearable
              style="width:140px"
              size="small"
              v-model="searchParams.startTime"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="开始日期"
            >
            </el-date-picker>
          </div>
          <div class="el-select">
            <el-date-picker
              clearable
              style="width:140px"
              size="small"
              :picker-options="endTimepickerOptions"
              v-model="searchParams.endTime"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <div
            class="el-select"
            v-if="isTeacherRole == false"
          >
            <el-select
              style="width:130px"
              size="small"
              clearable
              v-model="searchParams.teacherId"
              placeholder="教师筛选"
              @change="search"
            >
              <el-option
                v-for="item in teacherList"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="el-select">
            <el-select
              style="width:130px"
              size="small"
              clearable
              v-model="searchParams.taId"
              placeholder="助教筛选"
              @change="search"
            >
              <el-option
                v-for="item in teacherList2"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="el-select">
            <el-select
              style="width:150px"
              size="small"
              clearable
              v-model="searchParams.campusId"
              placeholder="校区筛选"
              @change="getClassroom"
            >
              <el-option
                v-for="item in campusList"
                :key="item.id"
                :label="item.campusName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="el-select"
            v-show="searchParams.campusId != null && searchParams.campusId != ''"
          >
            <el-select
              style="width:130px"
              size="small"
              clearable
              v-model="searchParams.classRoomId"
              placeholder="教室筛选"
              @change="search"
            >
              <el-option
                v-for="item in classroomList"
                :key="item.id"
                :label="item.classroomName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="el-select"
            v-if="isTeacherRole == false"
          >
            <el-select
              style="width:130px"
              size="small"
              clearable
              v-model="searchParams.attend"
              placeholder="考勤筛选"
              @change="search"
            >
              <el-option
                label="正常上课"
                value="normal"
              ></el-option>
              <el-option
                label="缺勤"
                value="absent"
              ></el-option>
            </el-select>
          </div>
          <div class="search-child">
            <el-button
              size="small"
              type="primary"
              @click="search()"
            >查询</el-button>
            <el-button
              size="small"
              type="primary"
              @click="searchReset()"
            >显示所有上课日历</el-button>
          </div>
        </div>
        <div>
          <ul class="my-days">
            <li
              v-for="item in days"
              :key="item"
              :class="item==day ? 'active' : ''"
              @click="changeDay(item)"
            >{{item}}</li>
          </ul>
        </div>
        <div>
          <el-table
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column
              prop="classDateStr"
              width="120"
              label="开课日期"
            >
            </el-table-column>
            <el-table-column
              prop="classBeginTime"
              width="100"
              label="开课时间"
            >
            </el-table-column>
            <el-table-column
              prop="infoName"
              align="center"
              width="200"
              label="开班名称"
            ></el-table-column>
            <el-table-column
              prop="schoolName"
              align="center"
              width="200"
              label="学校"
            ></el-table-column>
            <el-table-column
              prop="campusName"
              align="center"
              width="150"
              label="校区"
            ></el-table-column>
            <el-table-column
              prop="classRoomName"
              align="center"
              width="180"
              label="教室"
            ></el-table-column>
            <el-table-column
              prop="teacherRealName"
              label="教师"
            ></el-table-column>
            <el-table-column
              prop="taRealName"
              label="助教"
            ></el-table-column>
            <el-table-column
              prop="courseName"
              align="center"
              width="250"
              label="课程包名称"
            ></el-table-column>
            <el-table-column
              prop="unitName"
              align="center"
              width="200"
              label="课程单元"
            ></el-table-column>
            <el-table-column
              v-if="isTeacherRole == false"
              prop="attend"
              label="是否上课"
            >
              <template slot-scope="scope">
                {{scope.row | attendFilter}}
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              width="150"
              label="操作"
            >
              <template slot-scope="scope">
                <el-link
                  style="margin-right:5px"
                  type="primary"
                  @click="toStudy(scope.row)"
                >去上课</el-link>
                <el-link
                  v-if="scope.row.workId && scope.row.workId.length >=1 && isTeacherRole == false"
                  type="primary"
                  @click="toReviewEvaluate(scope.row)"
                >查看教师评价</el-link>
              </template>
            </el-table-column>
          </el-table>
          <div class="content-pagination">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="searchParams.pageNo"
              :page-size="searchParams.pageSize"
              layout="total, prev, pager, next,jumper"
              :total="dataTotal"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="编辑昵称"
      width="400px"
      :visible.sync="showDialog"
      :show-close="false"
      @closed="closeDialog"
      :close-on-click-modal="false"
    >
      <el-form
        :model="dialogForm"
        ref="dialogForm"
        :rules="changeRules"
      >
        <el-form-item
          label="新昵称"
          :label-width="'80px'"
          prop="nickname"
        >
          <el-input
            v-model="dialogForm.nickname"
            autocomplete="off"
            placeholder="请输入昵称（6个汉字以内）"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="postNickname"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isStudent, isTeacher } from "@/utils/auth";
import Curriculums from '@/components/Curriculums';
import {
  getUserInfo, getMyStudyData, getMyLessonList, getTeacherLessonList,
  getCampusList, getClassroomList, changeNickname, getTeacherList
} from "@/api/user"
import Vue from 'vue'
import { mapState } from 'vuex';
import { getUserInfo as getUserBaseInfo } from '@/utils/auth';

import { LABS } from '@/utils/page-resource';
export default {
  name: 'StudyData',
  components: {
    Curriculums
  },
  data() {
    return {
      isTeacherRole: false,
      dataTotal: 0,
      showDialog: false,
      tableData: [],
      year: '',
      month: '',
      day: 0,
      isSelf: !this.$route.query.uid || this.$route.query.uid == Vue.ls.get('Login_Userid'),
      dialogForm: {
        nickname: ''
      },
      campusList: [],
      classroomList: [],
      teacherList: [],
      teacherList2: [],
      searchParams: {
        startTime: null,
        endTime: null,
        campusId: null,
        classRoomId: null,
        attend: null,
        taId: null,
        teacherId: null,
        pageNo: 1,
        pageSize: 10
      },
      studyDataNumber: {
        absentClassNumber: 0,
        absentWorkNumber: 0,
        courseUnitNumber: 0,
        normalClassNumber: 0,
        totalClassNumber: 0
      },
      uid: this.$route.query.uid,
      userInfo: {
        name: '',
        avatar: ''
      },
      userType: 1, //1个人，2教师，3机构，4学校，5other
      endTimepickerOptions: {
        disabledDate: (time) => {
          if (!this.searchParams.startTime) {
            return false;
          }
          return (
            time.getTime() <
            new Date(this.searchParams.startTime.replace(/-/g, "/")).getTime()
          );
        },
      },
      changeRules: {
        nickname: [
          {
            validator: (rule, value, callback) => {
              if (value == '') {
                callback(new Error('请输入昵称'));
              } else if (!/^[^x00-xff]{1,6}$/.test(value)) {
                callback(new Error('请输入最多6个中文'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
        ],
      },
    };
  },
  computed: {
    days() {
      let date = new Date()
      this.year = date.getFullYear()
      this.month = date.getMonth() + 1
      this.day = date.getDate();
      let d = new Date(this.year, this.month, 0)
      return d ? d.getDate() : 30
    },
    ...mapState({
      titleConfig: (state) => state.siteConfigs,
    }),
    userBaseInfo: function () {
      return getUserBaseInfo();
    },
  },
  watch: {
    $route(newRoute) {
      this.uid = newRoute.query.uid,
        this.init();
    },
    showDialog(value) {
      if (value) {
        this.dialogForm.nickname = this.userInfo.realname;
      }
    },
    day: {
      handler(val) {
        let val2 = val * 1 < 10 ? `0${val}` : val;
        let month = this.month * 1 < 10 ? `0${this.month}` : this.month;
        if (!val) {
          this.searchParams.startTime = null
        } else {
          this.searchParams.startTime = (this.year + "-" + month + "-" + val2)
        }
        this.searchParams.endTime = this.searchParams.startTime;
        // debugger
        this.search();
      },
    },
  },
  filters: {
    attendFilter(val) {
      let beginTime = new Date(val.classBeginTime).getTime();
      let now = new Date().getTime();
      if (beginTime > now) {
        return '未开课'
      } else {
        if (val.attend == 'normal') {
          return '正常上课';
        } else {
          return '缺勤'
        }
      }
    }
  },
  mounted() {
    // let today = new Date();
    // let beginTime = today.toLocaleDateString();
    // this.searchParams.startTime = beginTime;
    isTeacher().then(isT => {
      if (isT) {
        this.isTeacherRole = true;
      } else {
        this.isTeacherRole = false;
      }
      // debugger
      // this.search();
    })
    this.init();
  },
  methods: {
    searchReset() {
      this.searchParams = {
        startTime: null,
        endTime: null,
        campusId: null,
        classRoomId: null,
        attend: null,
        taId: null,
        teacherId: null,
        pageNo: 1,
        pageSize: 10
      }
      this.day = 0
      this.search();
    },
    init() {
      if (!this.uid && !Vue.ls.get('Login_Userid')) {
        this.$router.push(`/login?redirect_uri=${location.href}`)
        // window.location.href = window.location.origin+"/passport/dologin?url="+encodeURI(location.href)
      } else {
        this.getUserInfo();
        this.getMyStudyData();
        this.getCampus();
        this.getClassroom();
        this.getTeacherList();
        // this.search();
      }
    },
    postNickname() {
      this.$refs.dialogForm.validate(isOk => {
        if (isOk) {
          changeNickname(this.dialogForm).then(res => {
            if (res.success) {
              this.$notify({
                title: '系统提示',
                message: res.message || '修改昵称成功',
                type: 'success'
              });
              this.$store.dispatch('ChangeNickname', this.dialogForm.nickname);
              setTimeout(() => {
                location.reload();
              }, 1000)
            } else {
              this.$notify({
                title: '系统提示',
                message: res.message || '修改失败：未知错误',
                type: 'warning'
              });
            }
          })
        }
      });
    },
    closeDialog() {
      // console.log(this.$refs.dialogForm)
      this.$refs.dialogForm.resetFields();
    },
    search() {
      if (this.isTeacherRole == true) {
        getTeacherLessonList(this.searchParams).then(res => {
          if (res.success) {
            this.tableData = res.result.records;
            this.dataTotal = res.result.total;
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      } else {
        // debugger
        getMyLessonList(this.searchParams).then(res => {
          if (res.success) {
            this.tableData = res.result.records;
            this.dataTotal = res.result.total;
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      }
    },
    toStudy(item) {
      // const {courseUnitStudyLink,unitName} = item;
      let newLink = item.courseUnitStudyFullLink;
      if (newLink) {
        //   const links = courseUnitStudyLink.split("?");
        //   let params = "";
        //   links.forEach((item,index) => {
        //     if(index===0){
        //       const newItem = item.replace('/','');
        //       params += `?lab=${newItem}`;
        //     }else{
        //       params += `&${item}`;
        //     }
        //   });
        //   params += `&unitName=${item.unitName}`;
        //   params += `&classPlanId=${item.id}`;
        //   debugger
        //   const newLink = `${window.location.protocol}//${this.titleConfig.portalDomain}/teaching${params}`;
        newLink = newLink + "&classPlanId=" + item.id + '&classInfoId=' + item.classInfoId;
        // newLink = newLink + "&classPlanId=" + item.classInfoId + '&classInfoId=' + item.id
        window.open(newLink);
      }
    },
    toReviewEvaluate(record) {
      window.open(record.commentLink);
      //todo
      // this.$router.push({ path: `/evaluate`, query: { examAnswerId: false, workId: record.workId } })
    },
    handleCurrentChange(val) {
      this.searchParams.pageNo = val;
      this.search();
    },
    getMyStudyData() {
      getMyStudyData().then(res => {
        if (res.success) {
          this.studyDataNumber = res.result;
        }
      })
    },
    getUserInfo() {
      getUserInfo({ uid: this.uid || Vue.ls.get('Login_Userid') }).then(res => {
        if (res.success) {
          this.userInfo = res.result;
        }
      })
    },
    getCampus() {
      getCampusList({ pageSize: 999, pageNo: 1 }).then(res => {
        if (res.success) {
          this.campusList = res.result.records;
        }
      })
    },
    getClassroom() {
      this.searchParams.classRoomId = null;
      getClassroomList({ schoolCampusId: this.searchParams.campusId }).then(res => {
        if (res.success) {
          this.classroomList = res.result;
          // this.search();
        }
      })
    },
    getTeacherList() {
      getTeacherList().then(res => {
        if (res.success) {
          this.teacherList = res.result.teachers;
          this.teacherList2 = res.result.tas;

        }
      })
    },
    changeDay(item) {
      this.day = item
    }
  },
};
</script>

<style lang="scss">
.user-info .el-form-item__error {
  white-space: initial;
}
</style>
<style lang="scss" scoped>
@import '~@/assets/css/views/user-info.scss';
.el-select {
  display: inline-block;
  position: relative;
  margin-bottom: 5px;
  margin-right: 5px;
}
.search-child {
  display: inline-block;
  vertical-align: top;
  float: right;
}
.btn-title {
  margin: 20px 10px 10px 0;
  button {
    margin-right: 10px;
    color: #fff;
    background-color: #0081ef;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    width: 140px;
    height: 40px;
    line-height: 30px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #0081ef;
    line-height: 26px;
    -webkit-transition: all 0.3s;
    font-weight: bold;
    &:hover {
      border: 2px solid #f59a23;
      background-color: #f59a23;
    }
  }
}
.content-pagination {
  margin-top: 20px;
  padding-bottom: 50px;
  text-align: right;
}
.search {
  margin: 20px 10px 10px 0;
}
.my-days {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  list-style: none;
  padding-inline-start: 0px;
  justify-content: space-around;
}
.my-days li {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  margin: 2px;
}
.my-days li.active {
  background: #2a97fa;
  border-radius: 24px;
  color: #ffffff;
}
.my-days-disable li.active {
  background: #f5f5f5;
  border-radius: 24px;
  color: #c5c5c5;
}
</style>
